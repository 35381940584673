.banner-section h1 {
  font-family: MineCraft;
  font-size: 96px;
  line-height: 96px;
}
.banner-section h1 span {
  display: block;
}
.banner-section p {
  font-family: Robert;
  font-size: 16px;
  font-weight: 300;
  margin: 30px 0 0;
}
.banner-section {
  padding: 20px 0 150px;
}
.dropdown-row {
  display: flex;
  flex-direction: row;
}
.dropdown-row .dropdown-items {
  margin-right: 15px;
}
.dropdown-toggle {
  background-color: #171717;
  border: 0;
  border-radius: 0;
  height: 55px;
  padding: 0 20px;
}
.dropdown-toggle:hover,
.dropdown-toggle:focus,
.dropdown-toggle:active:focus,
.show > .btn-primary.dropdown-toggle {
  background-color: #171717;
  color: #ffffff;
  box-shadow: none;
}
.dropdown-toggle:focus {
  box-shadow: none;
}
.dropdown-row .dropdown-toggle:after {
  margin-left: 10px;
}
.nft-card-row {
  margin: 36px -15px 0;
  display: flex;
  flex-wrap: wrap;
}
