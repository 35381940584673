@import url("../register.css");
.mt-144 {
  margin-top: 144px;
}
.forgot-password {
  font-family: Robert;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-top: 16px;
  color: white;
}
.password-container {
  position: relative;
}

.text-right{
  text-align: right;
}
.password-eye {
  position: absolute;
  top: 24px;
  left: 405px;
  cursor: pointer;
}
.button-active {
  background: #ffd600;
  color: black;
}
