a{
  text-decoration: none;
}
.slick-slide .card-wrapper {
  width: 100%;
}
.slick-slider .slick-next {
  position: absolute;
  bottom: -50px;
  top: auto;
  right: 0px;
  background: url("../assets/icons/right.svg") no-repeat;
  background-position: center;
}
.admin-no-nft-text {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 116px;
  line-height: 116px;
  color: #ffd600;
}
.slider-wrapper {
  margin-bottom: 40px;
}
.slider-wrapper1 {
  margin-bottom: 10px;
}
.slick-slider .slick-prev {
  position: absolute;
  bottom: -50px;
  top: auto;
  right: 39px;
  left: auto;
  background: url("../assets/icons/left.svg") no-repeat;
  background-position: center;
  z-index: 111;
}
.slick-slider .slick-prev:before,
.slick-slider .slick-next:before {
  display: none;
}
.slick-list {
  margin: 0px -15px;
}
.slider-top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 64px;
}
.slider-top-contact {
  font-family: MineCraft;
  font-weight: 400;
}
.slider-top-contact h6 {
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
}
.slider-top-contact h1 {
  font-size: 48px;
  line-height: 48px;
  color: #ffd600;
}
@media screen and (max-width: 767px) {
  .slider-wrapper {
    margin-bottom: 100px;
  }
  .slider-wrapper1 {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 575px) {
  .banner-section h1 {
    font-size: 50px;
    line-height: 50px;
  }
  .slider-top-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }
  .slider-top-contact:first-child {
    margin-bottom: 15px;
  }
}

.mr-10{
  margin-right: 10px;
}
.pt-10{
  margin-top: 10px;
}
