/* #root div {
  width: 98%;
} */
.game-opt {
  padding: 10px 12px;
  font-family: Roobert;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  border: 1px solid transparent;
}
.game-opt:hover {
  background: #ffd600;
  border: 1px solid #ffd600;
  box-sizing: border-box;
  border-radius: 32px;
  color: #000000;
}
.game-opt:hover .yellow-text {
  color: #000000;
}
.game-opt.active {
  background: #ffd600;
  border: 1px solid #ffd600;
  box-sizing: border-box;
  border-radius: 32px;
}
.navbar-light .navbar-brand {
  color: #fff !important;
}

.navbar-light .navbar-toggler {
  background: #ffd600;
}

.container {
  margin-top: 5rem;
  margin-bottom: 4.5rem;
}

.title {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 56px;
  line-height: 64px;
}

.steps {
  height: 24px;
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  color: #ffffff;
}

.stepdesc {
  margin-bottom: 0;
  font-family: Roobert;
  font-size: 16px;
  line-height: 19px;
}

.progress-bar {
  background-color: #ffd600;
}
.slots {
  font-family: Roobert;
}
.join {
  margin-top: 5rem;
}
.join .join-btn {
  background: #9c9c9c;
  border-color: #9c9c9c;
  font-family: Roobert;
  font-size: 16px;
  color: #000;
}
.join .join-btn.active {
  background: #ffd600;
  border-color: #ffd600;
}

.confirm:hover {
  cursor: pointer;
}

@media screen and (max-width: 940px) {
  .aligninst {
    text-align: center;
  }

  .title {
    font-size: 36px;
    line-height: 34px;
  }
}
@media screen and (max-width: 600px) {
  .navbar-main {
    flex-direction: column;
  }
  .navbar-logo {
    align-self: baseline;
  }
  .navbar-login {
    margin-left: unset;
    margin: 1rem;
  }
  .nav {
    margin-left: unset;
  }
}
@media screen and (max-width: 400px) {
  .navbar-main .nav,
  .navbar-main .navbar-login {
    align-self: baseline;
  }
  .step-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .steps {
    margin: 1rem 0;
    width: auto !important;
  }
}

.mtb {
  margin-top: 5rem;
  margin-bottom: 4.5rem;
}
