@import url("../fonts.css");
body {
  background-color: #0e0f11;
}
.phrase-title {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  margin-top: 59px;
  color: #ffffff;
}
.mt-24 {
  margin-top: 24px;
}
.phrase-1 {
  background: #1d59cf;
}
.yellow-color {
  color: #ffd600;
}
.font-minecraft-p {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  color: white;
}
.phrases {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
.phrase-word {
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
  height: 55px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.color-gray {
  color: rgba(80, 80, 80, 1);
}
.mt-53 {
  margin-top: 53px;
}
.check-button {
  width: 50%;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(156, 156, 156, 1);
  background-color: rgba(80, 80, 80, 1);
  font-family: Robert;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  position: absolute;
  bottom: 0;
}
.check-p {
  height: 100vh;
  background-color: black;
}

.pr-96 {
  padding-right: 96px;
}
.pl-96 {
  padding-left: 96px;
}
.pt-24 {
  padding-top: 24px;
}

.active-word-phrase {
  background: rgba(255, 214, 0, 0.1);
  border-radius: 6px;
  color: rgba(255, 214, 0, 1);
}
.cursor-pointer {
  cursor: pointer;
}
.active-button-check {
  background: #ffd600;
  color: black;
  cursor: pointer;
}
.circle-green {
  background: #00ff85;
  border-radius: 20px;
  width: 8px;
  height: 8px;
  margin-right: 5px;
}
.circle-orange {
  background: #ff7324;
  border-radius: 20px;
  width: 8px;
  height: 8px;
  margin-right: 5px;
}
.circle-red {
  background: #ff1f1f;
  border-radius: 20px;
  width: 8px;
  height: 8px;
  margin-right: 5px;
}
.phrase-desc {
  width: 100%;
}
.border-phrase {
  padding-top: 64px;
  margin-top: 64px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.orange-phrase {
  color: #ff7324;
}
.red-phrase {
  color: #ff1f1f;
}
.green-phrase {
  color: #00ff85;
}
.phrase-wrong-t {
  background: #ff1f1f;
}
.phrase-found-success {
  background-color: #00ff85;
}
.you-won-title {
  margin-top: 111px;
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 116px;
  line-height: 116px;
  /* identical to box height */

  color: #00ff85;
}
.canvas-container {
  position: relative;
}
.you-lost-title {
  margin-top: 111px;
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 116px;
  line-height: 116px;
  /* identical to box height */
  color: #ff1e00;
}
#my-canvas, #confetti {
  position: absolute;
  z-index: 10;
  width: 50%;
  height: 80vh;
}
.mb-32 {
  margin-bottom: 32px;
}
@media screen and (max-width: 1200px) {
  .main-phrase {
    flex-direction: column;
    align-items: center;
  }
  .main-phrase-wm {
    width: 100% !important;
  }
  .check-button {
    width: 100%;
    position: fixed;
    bottom: 0;
  }
  .pl-96 {
    padding-left: 10px;
  }
  .check-p {
    padding-bottom: 150px;
    height: unset !important;
  }
  .pr-96 {
    padding-right: 10px;
  }
  #my-canvas, #confetti {
    width: 100%;
    height: 50vh;
  }
}
