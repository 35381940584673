@font-face {
  font-family: MineCraft;
  src: url("../fonts/Minecraft.ttf");
}
@font-face {
  font-family: Robert;
  src: url("../fonts/Roobert-Regular.ttf");
}
@font-face {
  font-family: MonoSpec;
  src: url("../fonts/MonoSpec.ttf");
}
