.card-banner .detail-images-wpapper {
  max-width: 287px;
  min-height: 240px;
  max-height: 240px;
  margin: auto;
  background-color: black;
}
.card-banner .detail-images-wpapper h1 {
  font-size: 30px;
  line-height: 32px;
}
.from-wrapper h2 {
  font-size: 48px;
  line-height: 48px;
  color: #ffd600;
  font-family: MineCraft;
  max-width: 980px;
  text-align: center;
  margin: 48px auto 0;
}
.from-wrapper a {
  color: #ffd600;
  text-decoration: none;
  cursor: pointer;
}
.main-form {
  max-width: 288px;
  margin: auto;
}
.main-form p {
  font-family: Robert;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin-top: 64px;
  margin-bottom: 30px;
}
.main__form .form-group {
  position: relative;
  margin-bottom: 2rem;
}

.main__form .form-group label {
  font-family: Robert;
  font-size: 16px;
  color: #ffffff;
  padding: 0 20px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 0;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}

.main__form .form-group input {
  font-size: 16px;
  line-height: 19px;
  padding: 10px 20px;
  position: relative;
  z-index: 1;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.25);
  border-radius: 6px;
  color: #ffffff;
  height: 55px;
}
.main__form .form-group input:not(:placeholder-shown) + label,
.main__form .form-group input:focus + label {
  top: 2%;
  background: #0e0f11;
  z-index: 2;
  font-weight: 600;
  left: 10px;
  padding: 0 5px;
  font-size: 12px;
  line-height: 14px;
}

.main__form .form-group input:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.main__form .form-group input:focus::-webkit-input-placeholder,
.main__form .form-group input:focus:-ms-input-placeholder,
.main__form .form-group input:focus::-ms-input-placeholder,
.main__form .form-group input:focus::placeholder {
  font-size: 0;
}

.main__form .form-group input::-webkit-input-placeholder {
  color: transparent;
}

.main__form .form-group input:-ms-input-placeholder {
  color: transparent;
}

.main__form .form-group input::-ms-input-placeholder {
  color: transparent;
}

.main__form .form-group input::placeholder {
  color: transparent;
}
.main__form .form-group input:-webkit-autofill {
  background: transparent;
  -webkit-text-fill-color: white;
  transition: background-color 5000s ease-in-out 0s;
}



@-webkit-keyframes AnimationGradient {
  0% {
    background-position: 26% 0%;
  }
  50% {
    background-position: 75% 100%;
  }
  100% {
    background-position: 26% 0%;
  }
}

.btn-get {
  background: #ffd600;
  border-radius: 6px;
  height: 55px;
  padding: 9px 19px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  cursor: pointer;
}
.btn-get:disabled {
  background: rgba(255, 214, 0, 0.1);
  border: 2px solid rgba(255, 214, 0, 0.25);
  color: #ffd600;
  cursor: pointer;
}
.form-tab .btn-get {
  background: rgba(255, 214, 0, 0.1);
  border: 2px solid rgba(255, 214, 0, 0.25);
  color: #ffd600;
  cursor: pointer;
}
.form-tab {
  margin-top: 64px;
}
.banner-icon {
  text-align: center;
  margin-top: 130px;
}
@keyframes AnimationGradient {
  0% {
    background-position: 26% 0%;
  }
  50% {
    background-position: 75% 100%;
  }
  100% {
    background-position: 26% 0%;
  }
}

.green-tick {
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (max-width: 700px) {
  .from-wrapper h2 {
    font-size: 30px;
    line-height: 30px;
    margin: 30px auto 0;
  }
}

.bottom-wrapper {
  margin-top: 191px;
}
