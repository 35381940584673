@import url("../fonts.css");
.details-card {
  width: 297px;
  height: 248px;
  background: #000000;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.details-title {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;

  color: #ffd600;
}
.price-tag {
  font-family: MonoSpec;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}
.price-tag-text {
  color: #ffd600;
  position: absolute;
  right: 30px;
  bottom: 30px;
}
.price-title-text {
  position: absolute;
  left: 30px;
  bottom: 30px;
}
.cursor-pointer {
  cursor: pointer;
}
.mt-131 {
  margin-top: 131px;
}
.sold-out-text {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 116px;
  line-height: 116px;
  width: 600px;
  color: #ffd600;
}
.sold-out-text.no-past-game {
  font-size: 85px;
}
.paginationBttns {
  width: 100%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 20px;
  font-family: Minecraft;
}

.paginationBttns a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid white;
  color: #ffd600;
  cursor: pointer;
}

.paginationBttns a:hover {
  color: white;
  background-color: #ffd600;
}

.paginationActive a {
  color: white;
  background-color: #ffd600;
}

.paginationDisabled a {
  color: grey;
  background-color: none;
}