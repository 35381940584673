@media screen and (max-width: 1399px) {
  .home-main-text {
    font-size: 75px;
    line-height: 75px;
  }
}
@media screen and (max-width: 1300px) {
  .mt-288 {
    margin-top: 200px !important;
  }
}
@media screen and (max-width: 1250px) {
  .black-box-2 {
    margin-top: 20px;
  }
  .subs-button {
    width: 200px !important;
  }
  .card-wrapper {
    width: 33.333%;
  }
}
@media screen and (max-width: 1199px) {
  .container-main {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media screen and (max-width: 991px) {
  .title-win {
    font-size: 70px;
    line-height: 70px;
  }
  .mobile-only {
    display: unset !important;
  }
  .pc-only {
    display: none !important;
  }
  .mobile-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mobile-crown {
    left: 38px;
  }
  .mt-288 {
    margin-top: 100px !important;
  }
}
@media screen and (max-width: 767px) {
  .eth-d {
    width: 100px !important;
    height: 100px !important;
    top: -40px !important;
  }
  .card-cube {
    display: none;
  }
  .mobile-list-d {
    position: relative;
    width: 375px !important;
  }
  .card-list {
    margin-top: 20px !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    height: initial !important;
  }
  .main-card {
    margin-right: 0px !important;
    margin-bottom: 32px;
    width: 100% !important;
    background: #171717;
    border-radius: 8px;
  }
  .footer-text {
    font-size: 45px !important;
    line-height: 45px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mt-192 {
    margin-top: 92px !important;
  }
  .float-end {
    text-align: center !important;
  }
  .title-win {
    font-size: 56px !important;
    line-height: 56px !important;
    margin-top: 20px;
  }
  .subs-input-group {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .subs-input {
    width: 300px !important;
    margin-right: 0px !important;
    margin-bottom: 10px !important;
  }
  .black-box-2 {
    padding: 40px !important;
  }
  .mobile-footer {
    flex-direction: column;
  }
  .footer-l {
    width: 100% !important;
    padding-bottom: 10px;
  }
  .card-wrapper {
    width: 50%;
  }
  .banner-section {
    padding: 10px 0 70px;
  }
  .banner-section1 {
    padding: 10px 0 10px;
  }
}
@media screen and (max-width: 575px) {
  .card-wrapper {
    width: 100%;
  }
  .navbar-login {
    height: 2px;
    font-size: 10px;
    width: 115px;
  }
  .container-main {
    width: "100vw";
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media screen and (max-width: 400px) {
  
}
