@import url("../fonts.css");
.admin-welcome-text {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 116px;
  line-height: 116px;
  padding-top: 134px;
  color: #ffd600;
}
.home-no-game-text {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 96px;
  line-height: 96px;
  margin-bottom: 10px;
  color: #ffd600;
}
.admin-home-button {
  background: #ffd600;
  border: 2px solid rgba(255, 214, 0, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  width: 165px;
  height: 55px;
  color: black;
  font-family: Robert;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-top: 66px;
}
.mr-5{
  margin-right: 5px;
}
.pointer{
  cursor: pointer;
}
.pointer:hover{
  cursor: pointer;
  color: #ffd600;
}
@media screen and (max-width: 800px) {
  .admin-welcome-text {
    font-size: 50px;
    line-height: 60px;
  }
}
