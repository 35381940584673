@import url("../fonts.css");
.nft-details-container {
  margin-top: 105px;
}
.nft-title {
  width: 50%;
  height: 522px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  line-height: 40px;
  /* identical to box height */
  font-size: 100px;
  text-align: center;
  background-color: black;
  color: #ffd600;
}
.nft-details-text {
  margin-left: 114px;
  margin-top: 32px;
}
.table-title-text {
  font-family: Robert;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;

  color: #ffffff;
}

.table-value-text {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height */
  padding-left: 20px;

  color: #ffffff;
}

.table-value-text-small {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height */
  padding-left: 20px;

  color: #ffffff;
}
.table-nft-row {
  border-bottom: 50px transparent solid;
}
.download-nft-button {
  border: 1px solid #ffd600;
  box-sizing: border-box;
  width: 146px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-family: Robert;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  color: #ffd600;
}
.nft-button-m {
  margin-top: 200px;
}
@media screen and (max-width: 1200px) {
  .nft-details-text {
    margin-left: 0px;
    margin-top: 30px;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .nft-title {
    width: 100%;
  }
  .nft-button-m {
    margin-top: 0px;
    margin-right: 30px;
  }
}
@media screen and (max-width: 500px) {
  .nft-details-text {
    margin-left: 0px;
    margin-top: 30px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
  }
  .nft-button-m {
    margin-right: 0px;
  }
}
