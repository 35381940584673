@import url('./bootstrap.min.css');
@import url('./fonts.css');
body {
  background-color: rgba(14, 15, 17, 1);
  color: white;
}
.font-roobert {
  font-family: Roobert;
}
.red-text {
  color: #ff1f1f;
}
.black-text {
  color: #000000;
}
.mt-40 {
  margin-top: 40px;
}
.cursor {
  cursor: pointer;
}
.hover {
  cursor: pointer;
}
.hover:hover {
  color: #ffffff;
}

.container-main {
  padding-left: 96px;
  padding-right: 96px;
}
.navbar-logo {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  padding-left: 0;
  cursor: pointer;
}
.navbar-main .dropdown-toggle span {
  margin-right: 4px;
  display: inline-block;
}
.navbar-main .dropdown-toggle {
  font-size: 16px;
  min-width: 100px;
  display: flex;
  align-items: center;
  background-color: transparent;
  color: rgba(255, 214, 0, 1);
  font-weight: 600;
  padding: 0;
}
.navbar-main .dropdown-toggle:focus {
  box-shadow: none !important;
  background-color: transparent;
  color: rgba(255, 214, 0, 1);
  outline: 0;
}
.navbar-main .dropdown-menu {
  background: #000;
  border: 1px solid rgb(255 214 0 / 20%);
  padding: 0;
}
.navbar-main .dropdown-menu a {
  color: rgba(255, 214, 0, 1);
}
.navbar-main .dropdown-menu a:hover {
  background: rgb(255 214 0 / 20%);
  cursor: pointer;
}
.navbar-main .dropdown-toggle::after {
  margin-left: auto;
}
.navbar-light .navbar-brand {
  color: #fff !important;
}
.navbar-light .navbar-toggler {
  background: #ffd600;
}
.yellow-text {
  color: rgba(255, 214, 0, 1);
}
.navbar-login {
  color: rgba(255, 214, 0, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 20px;
  background: rgba(255, 214, 0, 0.1);
  border: 2px solid rgba(255, 214, 0, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  width: 151px;
  height: 55px;
  font-family: Robert;
  font-size: 15px;
}
.home-main-text {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 96px;
  line-height: 96px;
  margin-bottom: 10px;
}
.home-secondary-text {
  font-family: Robert;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}
.footer-l {
  width: 120px !important;
}
.card-list {
  margin-top: 164px;
  min-height: 331px;
}
.main-card {
  margin-right: 16px;
  position: relative;
  width: 288px;
  text-align:center;
  /* height: auto; */
  background: #171717;
  border-radius: 8px;
  font-family: MonoSpec;
}
.main-card .card-images {
  border-radius: 8px 8px 0 0;
}
.main-card .centered {
  font-family: MineCraft;
}
.main-card-img {
  border-radius: 8px 8px 0 0;
  /* height: 228px;
  width: 240px; */
}
.card-text-content {
  padding: 16px;
  font-family: MonoSpec;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}
.card-tr {
  border-bottom: 20px solid transparent;
}
.card-cube {
  position: absolute;
  right: 0;
  top: 350px;
}
.mt-192 {
  margin-top: 192px;
}
.black-box {
  min-width: 550px !important;
  height: 2414px;
  background-color: #000000;
}
.mobile-only {
  display: none;
}
.info-card {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
}
.info-card-title {
  font-size: 48px;
  line-height: 48px;
}
.info-card-explain {
  font-family: Robert;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}
.mt-48 {
  margin-top: 48px;
}
.mt-288 {
  margin-top: 288px;
}
.card2-text {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  color: rgba(255, 214, 0, 1);
  position: absolute;
  bottom: 150px;
  left: 90px;
  right: 90px;
  z-index: 1;
  opacity: 1;
  transition: 1s;
  top: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-text-showcase {
  background-color: rgba(14, 15, 17, 1);
  width: 288px;
  height: 240px;
}
.card-2-m {
  position: relative;
}
.card2-settings {
  transition: 2s;
  opacity: 1;
  z-index: 0;
  width: 288px;
  height: 297px;
}
.cont-key {
  position: relative;
  width: 100%;
}
.rotated {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
}
.not-rotated {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}
.z-m {
  position: relative;
  z-index: 100;
  background-color: black;
}
.key-img {
  z-index: 2;
  position: absolute;
  right: 0px;
  top: 0;
  transition: 1s;
}
.mt-170 {
  margin-top: 170px;
}
.eth-crown {
  opacity: 0;
  transition: 1s;
  position: absolute;
  top: 10px;
}
.mobile-key-ani {
  transition: 0.7s;
  position: absolute;
  top: -15px;
  right: -120px;
}
.mobile-key-text {
  opacity: 1;
  transition: 0.5s;
}
.mobilekey-cover {
  position: relative;
}
.eth-crown-p {
  opacity: 0;
  transition: 1s;
  position: absolute;
  top: 10px;
}
.eth-crown-c {
  padding-top: 50px;
  position: relative;
}

.key-text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s;
  opacity: 1;
}
.ms-230 {
  margin-left: 230px;
}
.pt-356 {
  padding-top: 356px;
}
.mt-316 {
  margin-top: 316px;
}
.font-minecraft {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
}
.mt-285 {
  margin-top: 285px;
}
.dotted-line {
  border: 1px dashed #ffd600;
  width: 350px;
}
.pass-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px;
  width: 87px;
  height: 55px;
  color: rgba(255, 255, 255, 1);
  border-radius: 6px;
  border: 2px solid rgba(255, 255, 255, 0.1);
}
.pass-box.active {
  background: rgba(255, 214, 0, 0.1);
  color: rgba(255, 214, 0, 1);
  border: 0;
}
.mt-240 {
  margin-top: 240px;
}
.black-box-2 {
  background-color: #000000;
  padding: 226px 0px 202px 0px;
}
.black-box-wrapper {
  max-width: 965px;
}
.sub-title-win {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
}
.title-win {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 96px;
  line-height: 96px;
}
.mt-48 {
  margin-top: 48px;
}
.join-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  width: 125px;
  height: 55px;
  background: #ffd600;
  color: #000000;
  border: 2px solid rgba(255, 214, 0, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 24px;
}
.eth-d-main {
  position: relative;
}
.eth-d {
  position: absolute;
  top: -150px;
}
.subs-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 448px;
  height: 55px;
  background: rgba(255, 214, 0, 0.1);
  border: 2px solid rgba(255, 214, 0, 0.25);
  border-radius: 6px;
  color: #ffd600;
  cursor: pointer;
}
.subs-button a {
  color: #ffd600;
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.subs-input {
  width: 448px;
  height: 55px;
  border: 2px solid rgba(255, 255, 255, 0.25);
  border-radius: 6px;
  margin-right: 32px;
  background: #000000;
  padding-left: 20px;
  color: white;
}
.subs-input-group {
  margin-top: 43px;
}
.footer-text {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 96px;
  line-height: 96px;
}
.mt-36 {
  margin-top: 36px;
}
.footer-labels {
  font-family: Robert;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #ffd600;
}
.right-col {
  text-align: right;
}
.mb-51 {
  margin-bottom: 51px;
}
.justify-content-center-f {
  justify-content: center !important;
}
.navbar-user {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  display: flex;
}
.user-avatar {
  margin-left: 10px;
  font-size: 20px;
}
.card-wrapper {
  padding: 0 15px;
  width: 25%;
}
.card-item {
  background-color: #171717;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 50px;
  cursor: pointer;
}
.card-images {
  max-height: 240px;
  min-height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
}
.card-images h2 {
  margin-bottom: 0;
  font-family: MineCraft;
  font-size: 24px;
  text-align:center;
}
.card-footer {
  padding: 15px;
}
.card-footer-row {
  display: flex;
  justify-content: space-between;
  font-family: MonoSpec;
  font-size: 14px;
  text-transform: uppercase;
}
.card-footer-row:first-child {
  margin-bottom: 14px;
}
.card-wrapper a {
  text-decoration: none;
  color: #ffffff;
}
.main-button {
  background-color: #ffd600;
  color: #0e0f11;
  border: 1px solid rgb(255 214 0 / 25%);
  display: inline-flex;
  font-weight: 600;
  padding: 0 20px;
  border-radius: 6px;
  height: 55px;
  font-family: Robert;
  font-size: 16px;
  line-height: 55px;
}
.button-row .navbar-login {
  background-color: #ffd600;
  color: #0e0f11;
  border: 1px solid rgb(255 214 0 / 25%);
  display: inline-flex;
  font-weight: 600;
}

.Connect-Modal .modal-dialog-centered {
  max-width: 288px;
}
.Connect-Modal .modal-content {
  background-color: #000000;
  border: 1px solid rgb(255 214 0 / 20%);
}
.Connect-Modal .modal-body {
  padding: 15px;
}
.Connect-Modal .modal-body h4 {
  font-size: 24px;
  font-family: MineCraft;
  color: #ffffff;
  margin: 6px 0 45px;
}
.button-section .button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  line-height: 53px;
  border: 1px solid #ffd600;
  color: #ffd600;
  font-size: 16px;
  font-family: Robert;
  font-weight: 600;
  padding: 0 14px;
  background-color: transparent;
  margin-bottom: 16px;
  border-radius: 6px;
}
.modal {
  backdrop-filter: blur(52px);
  background-color: rgb(0 0 0 / 5%);
}
.Connect-Modal .modal-body p {
  font-size: 12px;
  font-family: Robert;
  font-weight: 400;
  line-height: 14.4px;
  margin: 41px 0 5px;
}
.Connect-Modal .modal-body p a {
  color: #ffd600;
  text-decoration: none;
}
.nav-dropdown-item {
  cursor: pointer;
}
.nav-dropdown-item:active {
  background-color: #ffd600 !important;
}
.wallet-connect {
  margin-left: auto;
  /* text-align: center; */
}
.wallet-connect button {
  background-color: transparent;
}
.wallet-connect button:hover,
.wallet-connect.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle,
.wallet-connect button:active {
  background-color: #ffd600 !important;
  border-color: #ffd600 !important;
  color: #000 !important;
  box-shadow: none !important;
}
.closeButton {
  position: absolute;
  right: 0;
  top: -56px;
  cursor: pointer;
}
.back-button {
  cursor: pointer;
  margin: -1px 12px 2px 0;
}
.word_image {
  width: 800px;
}

.account {
  color: #ffd600;
  text-transform: uppercase;
}

.share-modal .modal-dialog {
  width: 450px;
}
