@import url("../fonts.css");
body {
  background-color: rgba(14, 15, 17, 1);
}
.status-line {
  width: 33.3%;
  height: 8px;
  background-color: #505050;
}
.line-container {
  margin-top: 64px;
}
.numbers-clickable{
  cursor: pointer;
}
.input-admin-price {
  border: 2px solid rgba(255, 255, 255, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  background-color: rgba(14, 15, 17, 1);
  color: white;
  width: 200px;
  height: 55px;
  padding-left: 15px;
  margin-top: 7px;
  margin-bottom: 7px;
  font-family: Robert;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.nft-price-admin-table{
  width: 200px;
  font-family: MineCraft;
  color:gray;
  font-style: normal;
font-weight: normal;
font-size: 24px;
line-height: 36px;
cursor: pointer;
}
.active-nft-price{
  color:white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.line-active {
  background-color: #ffd600;
}
.status-explain {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  margin-top: 24px;
}
.input-number {
  border: 2px solid rgba(255, 255, 255, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  background-color: rgba(14, 15, 17, 1);
  color: white;
  width: 139px;
  height: 55px;
  padding-left: 15px;
  margin-top: 7px;
  margin-bottom: 7px;
  font-family: Robert;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-top: 71px;
}
.input-number::placeholder {
  color: white;
}
.font-minecraft {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  color: #505050;
}
.continue-button {
  background-color: #505050;
  border-radius: 6px;
  /* position: absolute;
  right: 96px;
  bottom: 50px; */
  min-width: 108px;
  height: 55px;
  color: rgba(156, 156, 156, 1);
  font-family: Robert;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.download-button {
  border: 1px solid #ffd600;
  box-sizing: border-box;
  border-radius: 6px;
  border-radius: 6px;
  position: absolute;
  right: 315px;
  bottom: 50px;
  width: 308px;
  height: 55px;
  font-family: Robert;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #ffd600;
}
.active-row {
  color: #ffd600;
}
.active-button {
  background: #ffd600;
  color: black;
}
.disabled {
  pointer-events: none;
}
.black-section-2 {
  background-color: black;
  height: 600px;
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 40px;
  margin-right: 100px;
  /* identical to box height */

  color: #ffd600;
}
.section-2-container {
  margin-top: 86px;
}
.table-font-minecraft {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height */
  padding-left: 20px;

  color: #ffffff;
}
.table-font {
  font-family: Robert;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  vertical-align: bottom;
  color: #ffffff;
}
.table-row {
  border-bottom: 50px transparent solid;
}
.arrows {
  width: 200px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.arrows i {
  cursor: pointer;
}
.arrows i:hover {
  color: #ffd600;
}
.admin-phrase-text-area {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  color: #ffd600;
  background-color: rgba(14, 15, 17, 1);
  border: none;
  width: 100%;
  margin-top: 48px;
  overflow: auto;
  outline: none;
  height: 100px;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none;
}
.admin-phrase-box {
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px;
  margin: 5px;
}
.box-active {
  background: rgba(255, 214, 0, 0.1);
  border: 1px solid transparent;
  color: #ffd600;
  box-sizing: border-box;
  border-radius: 6px;
}
.admin-phrase-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.admin-phrase-list::after {
  content: "";
  flex: auto;
}
.admin-phrase-info {
  font-family: Robert;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #505050;
}
.finish-button {
  background: #505050;
  border-radius: 6px;
  width: 256px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9c9c9c;
  cursor: pointer;
  pointer-events: none;
}
.finish-button-active {
  background: #ffd600;
  border-radius: 6px;
  pointer-events: unset;
  color: black;
  font-family: Robert;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

@media screen and (max-width: 1300px) {
  .black-section-2 {
    width: 100% !important;
  }
  .section-2-container {
    flex-direction: column;
  }
  .download-button {
    position: inherit;
  }
  .continue-button {
    position: inherit;
    margin-top: 10px;
    margin-bottom: 50px;
  }

  .list-main {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
  }
 
  .footer-text-phrase {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 1000px) {
  .admin-phrase-info {
    width: 300px;
  }
}

.right-bottom {
  display: fix;
  right: 0;
  bottom: 0;
}
