.detail-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}
.detail-row {
    display: flex;
    flex-direction: row;
}
.detail-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* justify-content: space-evenly; */
}
.text-title{
    text-align: center;
    margin-bottom: 48px;
    font-family:MineCraft;
    font-size:48px;
    font-weight:400;
    line-height:48px;
}
.text-money {
    font-size:96px;
    color: #ffd600;
    line-height:96px;
    font-weight:400;
    letter-spacing: 5px;
}
.detail-images {
    flex: 1;
    border-radius: 5px;
    /* padding-right: 20px; */

}
.mint-button {
    background-color: #ffd600;
    color: #0e0f11;
    border: 1px solid rgb(255 214 0 / 25%);
    display: inline-flex;
    font-weight: 600;
    padding: 0 60px;
    border-radius: 6px;
    height: 55px;
    font-family: Robert;
    width: 100%;
    font-size: 16px;
    line-height: 55px;
    text-align: center;
  }
.detail-card-text {
    flex-basis: 288px;
    max-width: 288px;
    margin-right: 30px;
  
}
.detail-images-wpapper {
    max-width: 768px;
    min-height: 640px;
    max-height: 640px;
    overflow: hidden;
    display: flex;
    margin-left: 30px;
    align-items: center;
    justify-content: center;
    background-color: #ffd600;
    border: 8px;
    border-radius: 6px;
}
.purchase-button {
    width: 100%;
    justify-content: center;
    padding:0px 60px;
    background: #ffd600;
    border-color: #ffd600;
    font-family: MineCraft;
    color: #0e0f11;
    border: 1px solid rgb(255 214 0 / 25%);
    display: inline-flex;
    font-weight: 600;
    border-radius: 6px;
    font-family: Robert;
    font-size: 16px;
    line-height: 55px;
}
.purchase-button:disabled {
    background: #9c9c9c;
    border-color: #9c9c9c;
    font-family: Roobert;
    font-size: 16px;
    color: #000;
}
.detail-images-wpapper h1 {
    font-size: 96px;
    line-height: 96px;
    font-family: MineCraft;
    margin: 0;
    text-transform: uppercase;
}
.detail-hd {
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    margin-bottom: 15px;
    font-family: Robert;
}
.price-text div:last-child {
    font-size: 24px;
    font-family: MonoSpec;
    color: #ffd600;
    margin-bottom: 48px;
}
.text-mint  div:last-child {
    font-size: 24px;
    font-family: MineCraft;
    margin-bottom: 48px;
}
.owner-text h3 {
    font-family: MineCraft;
    font-size: 24px;
    line-height: 24px;
    text-transform: uppercase;
    margin-bottom: 50px;
}
.owner-text p {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 45px;
    font-family: Robert;
}
.wait-text {
    font-size: 40px;
    line-height: 70px;
}
.stage {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 1rem 0;
    margin: 0 -5%;
    margin-left: 5px;
    overflow: hidden;
    width: 80px;
    height: 40px;
}
.dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #ffd600;
    color: #ffd600;
    box-shadow: 9999px 0 0 -5px #ffd600;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: 0.25s;
}
.dot-pulse::before,
.dot-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #ffd600;
    color: #ffd600;
}

.dot-pulse::before {
    box-shadow: 9984px 0 0 -5px #ffd600;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
}

.dot-pulse::after {
    box-shadow: 10014px 0 0 -5px #ffd600;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: 0.5s;
}
.pagination-box {
    display: flex;
    justify-content: end;
}
.pagination-box .page-link:hover {
    transition: 0.5s;
    opacity: 0.6;
}
.pagination-box .page-item.active .page-link {
    z-index: 3;
    color: black;
    background-color: #e9e7e7;
    border-color: #e9e7e7;
}
.pagination-box .page-item .page-link {
    z-index: 3;
    color: #e4e6eb;
    background-color: #171717;
    border-color: #e4e6eb;
}
.pagination-box .page-item.disabled .page-link {
    z-index: 3;
    color: #e4e6eb;
    background-color: #171717;
    border-color: #e4e6eb;
    opacity: 0.4;
}

@keyframes dotPulseBefore {
    0% {
        box-shadow: 9984px 0 0 -5px #ffd600;
    }
    30% {
        box-shadow: 9984px 0 0 2px #ffd600;
    }
    60%,
    100% {
        box-shadow: 9984px 0 0 -5px #ffd600;
    }
}

@keyframes dotPulse {
    0% {
        box-shadow: 9999px 0 0 -5px #ffd600;
    }
    30% {
        box-shadow: 9999px 0 0 2px #ffd600;
    }
    60%,
    100% {
        box-shadow: 9999px 0 0 -5px #ffd600;
    }
}

@keyframes dotPulseAfter {
    0% {
        box-shadow: 10014px 0 0 -5px #ffd600;
    }
    30% {
        box-shadow: 10014px 0 0 2px #ffd600;
    }
    60%,
    100% {
        box-shadow: 10014px 0 0 -5px #ffd600;
    }
}

@media screen and (max-width: 1250px) {
    .detail-images-wpapper h1 {
        font-size: 70px;
        line-height: 70px;
    }
    .detail-card-text { 
        margin-left: 20px;
    }
}
@media screen and (max-width: 1350px) { 

    .detail-card-text { 
        margin-left: 20px;
    }

}
@media screen and (max-width: 992px) {
    .detail-row {
        flex-direction: column;
    }
    .detail-images {
        width: 100%;
        padding-right: 0;
    }
    .detail-card-text {
        flex-basis: auto;
        max-width: 100%;
        margin-top: 40px;
       
    }
    .price-text div:last-child,
    .owner-text h3,
    .owner-text p {
        margin-bottom: 25px;
    }
}
@media screen and (max-width: 575px) {
    .detail-images-wpapper h1 {
        font-size: 50px;
        line-height: 50px;
    }
    .detail-images-wpapper {
        min-height: 300px;
        max-height: 300px;
    }
}
