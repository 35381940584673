@import url("./fonts.css");
.game-countdown {
  margin-top: 158px;
}
.game-countdown-title {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
}
.game-countdown-time {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 116px;
  line-height: 116px;
  text-align: center;
  color: #ffd600;
}
.game-countdown-subtitle {
  font-family: Minecraft;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  margin-top: 64px;
  /* identical to box height */

  text-align: center;
}
